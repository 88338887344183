
import React, { useState } from 'react' ;
import { GiHamburgerMenu } from 'react-icons/gi' ;
import { GrClose } from 'react-icons/gr' ;

import './Navbar.css' ;
import images from '../../constants/images' ;

const Navbar = () => {
    const [toggleMenu, setToggleMenu] = useState(false) ;
    return (
        <nav className="app__navbar">
            <div className="app__navbar-logo">
                <img src={ images.neia_logo } alt='logo' />
            </div>
            <ul className='app__navbar-links'>
                <li className="p__opensans"><a href='#home'>Home</a></li>
                <li className="p__opensans"><a href='#about'>About</a></li>
                <li className="p__opensans"><a href='#menu'>Menu</a></li>
                <li className="p__opensans"><a href='#contact'>Contact</a></li>
            </ul>
            <div className="app__navbar-login">
                <a href='https://neiasoftwaredesign.com' className='p__opensans'>Return to portfolio</a>
                <div />
                <a href='#login' className="p__opensans">Log In / Register</a>
                <div />
                <a href='/' className="p__opensans">Book Session/Class</a>
            </div>
            <div className="app__navbar-smallscreen">
                <GiHamburgerMenu color='rgb(85, 5, 125)' fontSize={27} onClick={ () => setToggleMenu(true) } />
                { toggleMenu && (
                    <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
                        <GrClose className='overlay__close' fontSize={ 27 } onClick={ () => setToggleMenu(false) } />
                        <ul className='app__navbar-smallscreen_links'>
                            <li className="p__opensans"><a href='#home'>Home</a></li>
                            <li className="p__opensans"><a href='#about'>About</a></li>
                            <li className="p__opensans"><a href='#menu'>Menu</a></li>
                            <li className="p__opensans"><a href='#awards'>Awards</a></li>
                            <li className="p__opensans"><a href='#contact'>Contact</a></li>
                        </ul>
                    </div>
                )}
            </div>
        </nav>
    )
}

export default Navbar ;

const services = [
    {
        service: '10 Minute Session',
        price: '$30',
    },
    {
        service: '30 Minute Session',
        price: '$50',
    },
    {
        service: '60 Minute Session',
        price: '$75',
    },
    {
        service: 'Monthly 60 Minute Session',
        price: '$840',
    },
    {
        service: 'Bi-Weekly 60 Minute Session',
        price: '$1,680',
    },
];

const courses = [
    {
        title: 'Mindset for Daily Life',
        price: '$200',
        
    },
    {
        title: 'Buisness Mindset',
        price: '$460',
        
    },
    {
        title: 'Mindset for Success',
        price: '$500',
        
    },
    {
        title: 'Mindset for Health',
        price: '$525',
        
    },
    {
        title: 'Mindset for Coaching',
        price: '$1260',
        
    },
];

export default { services, courses } ;